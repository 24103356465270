import React from "react";

const Intro = () => {
    const stylee={
        paragraph:{

        }
    }
    const redirectPage=()=>{
        window.location.href='/about';
    }
    return (
      <div className="intro" style={{marginTop:"60px"}}>
        <h2>The Grow Lotus Fintech Journey</h2>
        <p style={{fontSize:"1.1rem", marginBottom:"40px"}}>
          At Grow Lotus Fintech Private Limited, our journey began with a simple yet powerful vision: 
          to transform the financial landscape by combining innovation, expertise, and integrity. 
          Founded over 9 years ago by a team of passionate industry veterans, Grow Lotus Fintech 
          quickly established itself as a trusted name in the financial services sector. 
          Our story is one of dedication, perseverance, and a relentless pursuit of excellence. 
          From humble beginnings to becoming a leading force in the industry, Grow Lotus Fintech has 
          remained committed to its core values every step of the way.
        </p>
        <button className="serviceBtn" onClick={redirectPage}>Learn More About Us</button>
      </div>
    );
  };
  
  export default Intro;
  