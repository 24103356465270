import React from 'react';

const Header = () => {
 
  return (
    
    <div className="header">
      <h1 class="homePageHeading">Empowering Your Financial <br/>  Journey with Tailored Solutions</h1>
    </div>
  );
};

export default Header;
